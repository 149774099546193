import { Environment } from "../env";

export const configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/vmsak-sky.no',
    clientId: Environment.clientId
  }
};

export const scopes = [ Environment.clientId + "/Vergeregnskap.Access" ];

export const authRequest = {
  scopes: scopes
};