import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Temadetaljer from './temadetaljer';
import HouseIcon from '@mui/icons-material/House';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WorkIcon from '@mui/icons-material/Work';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MoneyIcon from '@mui/icons-material/Money';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';

const IconByTema = ({temaNavn}) => {
    if (temaNavn === 'Bank, lån og forsikring')
        return (<AccountBalanceIcon />);
    else if (temaNavn === 'Bolig og eiendeler')
        return (<HouseIcon />);
    else if (temaNavn === 'Arbeid, trygd og pensjon')
        return (<WorkIcon />);
    else if (temaNavn === 'Familie og helse')
        return (<EmojiPeopleIcon />);
    else if (temaNavn === 'Finans')
        return (<MoneyIcon />);
    else if (temaNavn === 'Gave og Arv')
        return (<CardGiftcardIcon />); 
    else if (temaNavn === 'Næring')
        return (<BusinessIcon />);
    else if (temaNavn === 'Personlige forhold')
        return (<PersonIcon />);
    else if (temaNavn === 'Andre forhold')
        return (<StarIcon />);    
    else 
        return (<StarIcon />);
}

const Tema = ({tema, aarA, aarB}) => {
    return (
        <Accordion className="accordion" defaultExpanded={true}>
            <AccordionSummary>
                <Typography variant="h5"><IconByTema temaNavn={tema.navn} />&nbsp;{tema.navn}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className='temadetaljer-container'>
                    <Temadetaljer 
                        temadetaljerAarA={tema.aarA} 
                        temadetaljerAarB={tema.aarB} 
                        aarA={aarA} 
                        aarB={aarB} 
                        spesifiseringerAarA={tema.spesifiseringerAarA}
                        spesifiseringerAarB={tema.spesifiseringerAarB}
                        />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default Tema;