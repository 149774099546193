import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const Spinner = ({loading}) => {
    return (
        <Backdrop open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

export default Spinner;