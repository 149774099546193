import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { hasAppAccess, fetchVerghaverNnin } from './appAPI';

const initialState = {
  loading: true, 
  loadingNnin: false,
  vergehaverId: ''
};

export const checkAppAccess = createAsyncThunk(
  'app/checkAppAccess',
  async (msalInstance) => {
    const response = await hasAppAccess(msalInstance);
    return response;
  }
);

export const getNnin = createAsyncThunk(
  'app/getNnin',
  async ({ msalInstance, vergehaverVeraId }) => {
    const response = await fetchVerghaverNnin(msalInstance, vergehaverVeraId);
    return response.nnin;
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,

  reducers: {
    setVergehaverId: (state, action) => {
      state.vergehaverId = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(checkAppAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAppAccess.fulfilled, (state, action) => {
        state.loading = false;
        state.authorized = action.payload.authorized;
        state.accessToBankdata = action.payload.accessToBankdata;
      })
      .addCase(getNnin.pending, (state) => {
        state.loadingNnin = true;
      })
      .addCase(getNnin.fulfilled, (state, action) => {
        state.vergehaverId = action.payload;
        state.loadingNnin = false;
      })
  },
});

export const { setVergehaverId } = appSlice.actions;

export const selectLoading = (state) => state.app.loading;
export const selectAuthorized = (state) => state.app.authorized;
export const selectAuthorizedForBankdata = (state) => state.app.accessToBankdata; 
export const selectVergehaverId = (state) => state.app.vergehaverId;
export const selectLoadingVergehaverNnin = (state) => state.app.loadingNnin;

export default appSlice.reducer;
