import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMsal } from "@azure/msal-react";

import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import Spinner from "../spinner";
import Transaksjoner from "./transaksjoner";

import Roller from "./roller"; 
import Moment from 'moment';

import Alert from '@mui/material/Alert';

import {
  selectAvailableBankdata,
  loadTransaksjoner,
  loadRoller,
  selectTransaksjoner,
  selectRoller,
  selectLoadingRoller,
  selectLoading,
  selectLoadingTransactions,
  setTabIndex,
  selectTabIndex
} from "../../features/bankdata/bankdataSlice";

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    },
    inl: {
      display: "inline-block"
    }
  });

const Bankdata = ({ vergehaverId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const msalInstance = useMsal().instance;

  const tabIndex = useSelector(selectTabIndex);

  const availableData = useSelector(selectAvailableBankdata);
  const transaksjoner = useSelector(selectTransaksjoner);
  const roller = useSelector(selectRoller);

  const getBanks = () => [...new Set(availableData.konti?.map(ad => ad.bank))];
  const getAccountsForBank = (bank) => [...new Set(availableData.konti?.filter(ad => ad.bank === bank).map(ad => ad.accountNumber))];
  const getYearsForAccount = (account) => availableData.konti?.filter(ad => ad.accountNumber === account).map(ad => ad.year);
  const getIncompleteMessages = () => [...new Set(availableData.statusDatainnhold?.filter(s => !s.komplett).map(s => "Melding fra " + s.bank + ": " + s.melding + ". Gjelder år: " + s.aar ))];

  const getMotkonti = (month) => {
    if (!transaksjoner || !transaksjoner.liste) {
      return [];
    }
    
    var motkonti = (month === '0' ? transaksjoner.liste : transaksjoner.liste.filter(trans => Moment(trans.bokfoertdato).format('M') === month))?.map(t => t.motpart?.kontonummer).filter(Boolean);
    return  [...new Set(motkonti)].sort();
  }
  
  const [banks] = useState(getBanks());
  const [selectedBank, setSelectedBank] = useState(banks[0]);
  
  const [accounts, setAccounts] = useState(getAccountsForBank(selectedBank));
  const [selectedAccount, setSelectedAccount] = useState(accounts[0]);
  
  const [years, setYears] = useState(getYearsForAccount(selectedAccount));
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const [selectedMonth, setSelectedMonth] = useState("1");

  const [motkonti, setMotkonti] = useState(getMotkonti(selectedMonth));
  const [selectedMotkonto, setSelectedMotkonto] = useState('0');

  const [incompleteMessages] = useState(getIncompleteMessages());

  const handleTabChange = (event, newTabIndex) => {
    dispatch(setTabIndex(newTabIndex));
  };

  const dispatchLoadTransaksjoner = (year, account) => {
    dispatch(
      loadTransaksjoner({
        msalInstance: msalInstance,
        vergehaverId: vergehaverId,
        accountNumber: account,
        year: year,
      })
    );  
  }

  const dispatchLoadRoller = (year, account) => {
    dispatch(
      loadRoller({
        msalInstance: msalInstance,
        vergehaverId: vergehaverId,
        accountNumber: account,
        year: year,
      })
    );  
  }

  const onChangeBank = (event) => {
    const bank = event.target.value;
    setSelectedBank(bank);

    const accounts = getAccountsForBank(bank)
    setAccounts(accounts);
    setSelectedAccount(accounts[0]);
    
    const years = getYearsForAccount(selectedAccount);
    setYears(years);
    setSelectedYear(years[0], selectedAccount);

    dispatchLoadTransaksjoner(selectedYear, selectedAccount);
    dispatchLoadRoller(selectedYear, selectedAccount);
  }

  const onChangeAccount = (event) => {
    const account = event.target.value;
    setSelectedAccount(account);

    const years = getYearsForAccount(account);
    setYears(years);
    setSelectedYear(years[0], account);

    setSelectedMotkonto('0');
    dispatchLoadTransaksjoner(selectedYear, account);
    dispatchLoadRoller(selectedYear, account);
  }

  const onChangeYear = (event) => {
    const year = event.target.value;
    setSelectedYear(year);

    setSelectedMotkonto('0');
    dispatchLoadTransaksjoner(year, selectedAccount);
    dispatchLoadRoller(year, selectedAccount);
  }

  const onChangeMonth = (event) => {
    const month = event.target.value;
    const motkonti = getMotkonti(month);
    
    setSelectedMotkonto('0');
    setMotkonti(motkonti);
    setSelectedMonth(month);
  }

  const onChangeMotkonto = (event) => {
    const motkonto = event.target.value;
    setSelectedMotkonto(motkonto);
  }

  useEffect(() => {
    const motkonti = getMotkonti(selectedMonth);
    setMotkonti(motkonti);

    // eslint-disable-next-line react-hooks/exhaustive-deps    
}, [transaksjoner])

  const loading = useSelector(selectLoading);
  const loadingTransactions = useSelector(selectLoadingTransactions);
  const loadingRoller = useSelector(selectLoadingRoller);

  const showSpinner = !loading && (loadingTransactions || loadingRoller);

  if (showSpinner) {
    return (<Spinner loading={ true } />); 
  }

  return (
        <div style={{backgroundColor: "white", width: "1305px", padding: "10px", borderRadius: "10px"}}>
               
            <Typography variant="h5" gutterBottom>Bankdata</Typography>
            
            { 
              incompleteMessages && incompleteMessages.map((message) => (<Alert sx={{ marginTop : "20px", fontSize: "16px" }} severity="warning">{ message }</Alert> ))
            }

            <Tabs value={tabIndex} onChange={handleTabChange} className={classes.root} >
              <Tab label="Transaksjoner" className={classes.root} />
              <Tab label="Roller" className={classes.root} />
            </Tabs>
            
            <Box sx={{ flexGrow: 1, marginTop : "40px" }}>
              <Grid container spacing={0}>  
                <Grid item xs={3}>    
                  <FormControl>
                    <InputLabel id="bank-select-label">Bank</InputLabel>
                    <Select
                      labelId="bank-select-label"
                      id="bank-select"
                      value={selectedBank}
                      label="Bank"
                      onChange={(e) => onChangeBank(e)}
                      sx={{ minWidth: "210px" }}
                    >
                      { banks.map((bank, index) => <MenuItem key={index} value={bank}>{bank}</MenuItem>) }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl>
                    <InputLabel id="kontonummer-select-label">Kontonummer</InputLabel>
                    <Select
                      labelId="kontonummer-select-label"
                      id="kontonummer-select"
                      value={selectedAccount}
                      label="Kontonummer"
                      onChange={(e) => onChangeAccount(e)}
                      sx={{ minWidth: "210px" }}
                    >
                       { accounts.map((account, index) => <MenuItem key={index} value={account}>{account}</MenuItem>) }
                     </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                { tabIndex === 0 &&  
                  <FormControl>
                    <InputLabel id="year-select-label">År</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedYear}
                      label="År"
                      onChange={(e) => onChangeYear(e)}
                      sx={{ minWidth: "100px" }}
                    >
                      { years.map((year, index) => <MenuItem key={index} value={year}>{year}</MenuItem>) }
                    </Select>
                  </FormControl>
                }
                </Grid>
                
                <Grid item xs={2}>    
                { tabIndex === 0 &&
                  <FormControl>
                    <InputLabel id="month-select-label">Måned</InputLabel>
                    <Select
                      labelId="month-select-label"
                      id="month-select"
                      value={selectedMonth}
                      label="Måned"
                      onChange={(e) => onChangeMonth(e)}
                      sx={{ minWidth: "200px" }}
                    >
                      <MenuItem key="0" value="0">Hele året</MenuItem>
                      <MenuItem key="1" value="1">Januar</MenuItem>
                      <MenuItem key="2" value="2">Februar</MenuItem>
                      <MenuItem key="3" value="3">Mars</MenuItem>
                      <MenuItem key="4" value="4">April</MenuItem>
                      <MenuItem key="5" value="5">Mai</MenuItem>
                      <MenuItem key="6" value="6">Juni</MenuItem>
                      <MenuItem key="7" value="7">Juli</MenuItem>
                      <MenuItem key="8" value="8">August</MenuItem>
                      <MenuItem key="9" value="9">September</MenuItem>
                      <MenuItem key="10" value="10">Oktober</MenuItem>
                      <MenuItem key="11" value="11">November</MenuItem>
                      <MenuItem key="12" value="12">Desember</MenuItem>
                    </Select>
                  </FormControl>
                }
                </Grid>

                <Grid item xs={4}>
                { tabIndex === 0 && motkonti && motkonti.length > 0 && 
                <FormControl>
                    <InputLabel id="motkonto-select-label">Motkonto</InputLabel>
                    <Select
                        labelId="motkonto-select-label"
                        id="motkonto-select"
                        value={selectedMotkonto}
                        label="Motkonto"
                        onChange={(e) => onChangeMotkonto(e)}
                        sx={{ minWidth: "180px" }}
                    >
                    <MenuItem key="0" value="0">Alle</MenuItem>
                    {
                        motkonti.map((mk) => (<MenuItem key={mk} value={mk}>{mk}</MenuItem>))
                    } 
                    </Select>
                </FormControl>
            }
                </Grid>

          
                { tabIndex === 0 && transaksjoner && transaksjoner.statusDatainnhold && !transaksjoner.statusDatainnhold.komplett && 
                  <Grid item xs={12}>
                    <Alert sx={{ marginTop : "20px", fontSize: "16px" }} severity="warning">{ transaksjoner.statusDatainnhold.melding }</Alert>
                  </Grid>
                }
                <Grid item xs={12}>
                    <Divider sx={{ marginTop : "40px" }} />
                </Grid>

                <Grid item xs={12}>
                  { tabIndex === 0 && <Transaksjoner transaksjoner={transaksjoner ? transaksjoner.liste : null} month={selectedMonth} motkonto={selectedMotkonto}/> }
                  { tabIndex === 1 && <Roller roller={roller} /> }    
                </Grid>

             </Grid>
            </Box>     
          
        </div>
    );
}  

export default Bankdata;