import React from 'react'
import { TableRow, TableCell, TableBody } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import SvgMore from "@mui/icons-material/ExpandMore";
import SvgLess from "@mui/icons-material/ExpandLess";
import GrunnlagRad from './grunnlagrad';
import SpesifiseringRad from './spesifiseringrad';
import { NumericFormat } from 'react-number-format';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles({
    root: {
        border:0,
        '&:focus': {
            outline:0,
            border:0,
        }
    },
  }
);

const ToggleExpandButton = ({ handleClicked, expanded}) => {
    const classes = useStyles();
    return (
        <IconButton onClick={handleClicked} size="small" className={classes.root}>
            {expanded && <SvgLess />}
            {!expanded && <SvgMore />}
        </IconButton>
    );
}
  
const StyledTableCell = withStyles((theme) => ({
    body: {
        backgroundColor: '#FBFBFB'
    }
  }))(TableCell);

const groupByBeskrivelse = (arr)=> {
    return arr.reduce(function(groups, item) {
      const val = item['GroupKey'];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
}

const Temadetaljerrad = ({ summary, beskrivelse, inntekt, formue, inntektsfradrag, formuesfradrag, grunnlag, spesifiseringer}) => {
    const [expand, setExpand] = React.useState(false);
    const [expandGrunnlag, setExpandGrunnlag] = React.useState(false);
    const [expandSpesifiseringer, setExpandSpesifiseringer] = React.useState(false);

    const handleExpand = () => {
        setExpand(!expand);
        setExpandGrunnlag(false);
        setExpandSpesifiseringer(false); 
    };

    const handleExpandGrunnlag = () => {
        setExpandGrunnlag(!expandGrunnlag);
    };

    const handleExpandSpesifiseringer = () => {
        setExpandSpesifiseringer(!expandSpesifiseringer);
    };

    const fontWeight = summary ? "bold" : "normal";
    
    const kontoer = () => Object.values(groupByBeskrivelse(spesifiseringer.filter(s => s.spesifiseringType === "Konto")))
         .flatMap(v => {
             v.push(
             {
                 SpesifiseringType: "Totalt", 
                 Beskrivelse: v[0].groupKey, 
                 Inntekt: v.reduce((sum, s) => sum + s.inntekt, 0), 
                 Formue: v.reduce((sum, s) => sum + s.formue, 0),
                 Inntektsfradrag: v.reduce((sum, s) => sum + s.inntektsfradrag, 0), 
                 Formuesfradrag: v.reduce((sum, s) => sum + s.formuesfradrag, 0), 
                 Detaljer: null
             }
             );
         return v;
        }); 

    return (    
        <TableBody>
            <TableRow>
                <StyledTableCell style={{ height:"40px"}}>
                { (grunnlag || spesifiseringer) && <ToggleExpandButton handleClicked={handleExpand} expanded={expand} /> }
                </StyledTableCell>
                <StyledTableCell><div style={{ fontWeight: 'bold' }}>{beskrivelse}</div></StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
                <StyledTableCell align="right"><NumericFormat style={{ fontWeight: fontWeight }} value={inntekt} displayType="text" thousandSeparator={' '}/></StyledTableCell>
                <StyledTableCell align="right"><NumericFormat style={{ fontWeight: fontWeight }} value={formue} displayType="text" thousandSeparator={' '}/></StyledTableCell>
                <StyledTableCell align="right"><NumericFormat style={{ fontWeight: fontWeight }} value={inntektsfradrag} displayType="text" thousandSeparator={' '}/></StyledTableCell>
                <StyledTableCell align="right"><NumericFormat style={{ fontWeight: fontWeight }} value={formuesfradrag} displayType="text" thousandSeparator={' '}/></StyledTableCell>
            </TableRow>
        
        { grunnlag && expand &&
            <TableRow>
                <TableCell><ToggleExpandButton handleClicked={handleExpandGrunnlag} expanded={expandGrunnlag} /></TableCell>
                <TableCell><div style={{ fontWeight: 'bold' }}>Grunnlag</div></TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">{expandGrunnlag ? <span style={{ fontWeight: 'bold' }}>Inntekt</span> :""}</TableCell>
                <TableCell align="right">{expandGrunnlag ? <span style={{ fontWeight: 'bold' }}>Formue</span> : ""}</TableCell>
                <TableCell align="right">{expandGrunnlag ? <span style={{ fontWeight: 'bold' }}>Inntektsfradrag</span> : ""}</TableCell>
                <TableCell align="right">{expandGrunnlag ? <span style={{ fontWeight: 'bold' }}>Formuesfradrag</span> : ""}</TableCell>
            </TableRow> 
        }
        
        { grunnlag && expandGrunnlag && grunnlag.map( (g, i) => <GrunnlagRad key={i} grunnlag={g} />) }
        
        { spesifiseringer && expand &&
            <TableRow>
                <TableCell><ToggleExpandButton handleClicked={handleExpandSpesifiseringer} expanded={expandSpesifiseringer} /></TableCell>
                <TableCell><div style={{ fontWeight: 'bold' }}>Spesifiseringer</div></TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">{expandSpesifiseringer ? <span style={{ fontWeight: 'bold' }}>Inntekt</span> : ""}</TableCell>
                <TableCell align="right">{expandSpesifiseringer ? <span style={{ fontWeight: 'bold' }}>Formue</span> : ""}</TableCell>
                <TableCell align="right">{expandSpesifiseringer ? <span style={{ fontWeight: 'bold' }}>Inntektsfradrag</span> : ""}</TableCell>
                <TableCell align="right">{expandSpesifiseringer ? <span style={{ fontWeight: 'bold' }}>Formuesfradrag</span> : ""}</TableCell>
            </TableRow>
        }
        
        { spesifiseringer && expandSpesifiseringer && spesifiseringer.filter(s => s.spesifiseringType !== "Konto").concat(kontoer()).map((s, i) => <SpesifiseringRad key={i} spesifisering={s} />) }
        </TableBody>);
}

export default Temadetaljerrad;