import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMsal } from "@azure/msal-react";

import YearSelector from "./yearSelector";
import ArkiverButton from "./arkiverButton";
import Tema from "./tema";
import { Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";

import {
    selectSkattedata,
    selectSkattedataOneYear,
    selectAvailableData,
    loadSkattedata,
    setSelectedOneYear,
    setSelectedYear,
    setTabIndex,
    selectSelectedYear,
    selectSelectedOneYear,
    selectTabIndex
  } from "../../features/skattedata/skattedataSlice";

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    },
    inl: {
      display: "inline-block"
    }
  });


const Skattedata = ({ vergehaverId }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const msalInstance = useMsal().instance;
        
    const skattedata = useSelector(selectSkattedata);
    const skattedataOneYear = useSelector(selectSkattedataOneYear);

    const selectedYear = useSelector(selectSelectedYear);
    const selectedOneYear = useSelector(selectSelectedOneYear);
    const tabIndex = useSelector(selectTabIndex);

    const availableData = useSelector(selectAvailableData);

    const handleTabChange = (event, newTabIndex) => {
      dispatch(setTabIndex(newTabIndex));
    };
    
    const onChangeYear = (showOneYear, event) => {
        const year = event.target.value;
        const oneYear = showOneYear;
        if (oneYear) { 
          dispatch(setSelectedOneYear(year));
        } else {
          dispatch(setSelectedYear(year));
        }

        dispatch(
          loadSkattedata({
            msalInstance: msalInstance,
            vergehaverId: vergehaverId,
            year: year,
            oneYear: oneYear,
          })
        );
      };

        return (
            <div style={{backgroundColor: "white", width: "1305px", padding: "10px", borderRadius: "10px"}}>
              <Typography variant="h5" gutterBottom>
                Skattedata
              </Typography>   
              <Tabs value={tabIndex} onChange={handleTabChange} className={classes.root} >
                <Tab label="Enkeltår" className={classes.root} />
                <Tab label="Sammenligning" className={classes.root} />
              </Tabs>
              <div>
                {availableData && skattedataOneYear && tabIndex === 0 && (
                  
                  <div>
                    <div className={classes.inl} style={{ marginLeft: "20px" }}>
                      <ArkiverButton saker={availableData?.find(av => av.year === skattedataOneYear.aarA).saker} vergehaverId={vergehaverId} year={skattedataOneYear.aarA} />
                    </div>
                    <div className={classes.inl} style={{ marginLeft: "1180px" }}>
                      <YearSelector
                        showOneYear={true}
                        selectedYear={selectedOneYear}
                        onChangeYear={onChangeYear}
                        availableData={availableData}
                      />
                    </div>
                  </div>

                )}
                {
                  skattedataOneYear &&
                  skattedataOneYear.temaer &&
                  tabIndex === 0 &&
                  skattedataOneYear.temaer.map((tema, i) => (
                    <Tema
                      key={i}
                      tema={tema}
                      aarA={skattedataOneYear.aarA}
                      aarB={skattedataOneYear.aarB}
                    />
                  ))}
              </div>
              <div>
                {availableData && skattedata && tabIndex === 1 && (
                  <div style={{ marginLeft: "1160px" }}>
                    <YearSelector
                      showOneYear={false}
                      selectedYear={selectedYear}
                      onChangeYear={onChangeYear}
                      availableData={availableData}
                    />
                  </div>
                )}
                {
                  skattedata &&
                  skattedata.temaer &&
                  tabIndex === 1 &&
                  skattedata.temaer.map((tema, i) => (
                    <Tema
                      key={i}
                      tema={tema}
                      aarA={skattedata.aarA}
                      aarB={skattedata.aarB}
                    />
                  ))}
              </div>
            </div>
    );
}  

export default Skattedata;