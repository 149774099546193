import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useMsal } from "@azure/msal-react";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#1E88E5',
    color: theme.palette.common.white,
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  accountButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    verticalAlign: "center",
  },
}));

const HeaderBar = () => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const msalInstance  = useMsal().instance;

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    
    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    }

    return (
        <table className={classes.root}>
          <tbody>
            <tr>
              <td>
                <Typography variant="h6" className={classes.title}>
                    Vergeregnskapskontroll
                </Typography>
              </td>
              <td align="right">
                <Button 
                  
                  className={classes.accountButton}
                  aria-controls="account-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  startIcon={<AccountCircle />}>
                    {msalInstance.getAllAccounts()[0].name}
                </Button>
                <Menu
                  id="account-menu"
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                  open={isMenuOpen}
                  onClose={handleProfileMenuClose}>
                    <MenuItem onClick={ () => msalInstance.logout() }>
                      <Typography>
                        Logg ut
                      </Typography>
                    </MenuItem>
                </Menu>
                </td>
            </tr>
          </tbody>        
        </table>

    );
}

export default HeaderBar;