import { Button } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SwapVert } from '@mui/icons-material';
import { useState } from 'react';

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
      padding: 0,
      border: 0,
      justifyContent: "right",
      "&:focus": {
        outline: 0,
        border: 0,
      },
      textTransform: 'none',
      fontSize: '12pt' 
    }
  });

// eslint-disable-next-line comma-spacing
const SortButton = ({
  children,
  active,
  direction,
  onClick,
  numeric
}) => {
  const classes = useStyles();

  const [asc, setAsc] = useState(direction === 'asc');

  const onSort = () => {
    if (active)
      setAsc(asc => !asc);
    else
      setAsc(false);
    onClick();
  };
  
  const startIcon = numeric ? (active ? (asc ? <ArrowUpwardIcon  /> : <ArrowDownwardIcon />) : <SwapVert color='disabled'/> ) : null;
  const endIcon = numeric ? null : (active ? (asc ? <ArrowUpwardIcon  /> : <ArrowDownwardIcon />) : <SwapVert color='disabled'/> );

  return (
    <Button
        aria-label='Sorter'
        className={classes.root}
        color='inherit'
        onClick={() => onSort()}
        startIcon={startIcon}
        endIcon={endIcon}
       >
          {children}
    </Button>
  );
};

export default SortButton;
