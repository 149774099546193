import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { Table, TableContainer, TableBody, TableCell, TableRow } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import LaunchIcon from '@mui/icons-material/Launch';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    root: {
        border:0,
        '&:focus': {
            outline:0,
            border:0,
        }
    },
  }
);

const OpenButton = ({handleSearchClicked}) => {
    const classes = useStyles();
    return (
      <IconButton
        className={classes.root}
        onClick={handleSearchClicked}
        size="small" >
        <LaunchIcon />
      </IconButton>
    );
}  

const CloseButton = ({handleSearchClicked}) => {
    const classes = useStyles();
    return (
      <Button
        className={classes.root}
        onClick={handleSearchClicked}>
        Lukk
      </Button>
    );
}  

const DetaljVerdi = ({detalj}) => {
    if (detalj.verditype === 'BELOEP') {
        return <NumericFormat value={detalj.verdi} displayType="text" thousandSeparator={' '}/>;
    } else if (detalj.verditype === "BOOL") {
        return detalj.verdi === "true" ? "Ja" : "Nei"
    } else {
        return <span>{detalj.verdi}</span>;
    }
}

const Detalj = ({detalj}) => {
    return (
    <TableRow>
        <TableCell>{detalj.beskrivelse}</TableCell>
        <TableCell>
           <DetaljVerdi detalj={detalj} />
        </TableCell>
    </TableRow>)
}

const SpesifiseringPopup = ({props}) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!props.detaljer) {
        return <span></span>
    }

    return (
        <div>
            <OpenButton handleSearchClicked={handleClickOpen} />
            <Dialog
              open={open}
              onClose={handleClose}>
                <DialogTitle>{props.beskrivelse}</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {props.detaljer.map( (d, i) => <Detalj key={i} detalj={d} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <CloseButton handleSearchClicked={handleClose} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SpesifiseringPopup;