import React, { Fragment } from 'react'
import { TableRow, TableCell } from '@mui/material';
import Moment from 'moment';
import IconButton from "@mui/material/IconButton";
import SvgMore from "@mui/icons-material/ExpandMore";
import SvgLess from "@mui/icons-material/ExpandLess";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    },
    inl: {
      display: "inline-block"
    }
  });
 
const ToggleExpandButton = ({ handleClicked, expanded}) => {
    const classes = useStyles();
    return (
        <IconButton onClick={handleClicked} size="small" className={classes.root}>
            {expanded && <SvgLess />}
            {!expanded && <SvgMore />}
        </IconButton>
    );
}  

const RolleRad = ({index, rolle}) => {
    
    const [expand, setExpand] = React.useState(false);
    
    const handleExpand = () => {
        setExpand(!expand);
    };

    return [
        <Fragment key={index}> 
            <TableRow>
                <TableCell sx={{ width: "20px" }}>
                    <ToggleExpandButton handleClicked={handleExpand} expanded={expand} />
                </TableCell>
                <TableCell sx={{ width: "200px" }}>{rolle.rettighetTilBruk}</TableCell>
                <TableCell sx={{ width: "140px" }}>{rolle.rollehaverNavn}</TableCell>
                <TableCell sx={{ width: "140px" }}>{Moment(rolle.startdato).format('DD.MM.YYYY')}</TableCell>
                <TableCell sx={{ width: "140px" }}>{rolle.sluttdato != null ? Moment(rolle.sluttdato).format('DD.MM.YYYY') : ""}</TableCell>
            </TableRow>
            { expand && 
            <TableRow>
                <TableCell colSpan={5}>
                    <table>
                        <tbody>
                            <tr><td><strong>Rollehaver</strong></td><td></td><td><strong>Adresse</strong></td><td></td></tr>
                            <tr><td valign='top'>Navn:</td><td valign='top'>{rolle.rollehaverNavn}&nbsp;&nbsp;</td><td>{rolle.postadresse}</td><td></td></tr>
                            <tr><td valign='top'>Telefon:</td><td valign='top'>{rolle.telefon}&nbsp;&nbsp;</td><td></td><td></td></tr>
                            <tr><td valign='top'>Epost:</td><td valign='top'>{rolle.epost}&nbsp;&nbsp;</td><td></td><td></td></tr>
                        </tbody>
                    </table>
                </TableCell>        
            </TableRow> 
            }
        </Fragment>
    ];

}

export default RolleRad;