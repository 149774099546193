import { Environment } from "../../env";
import { scopes } from "../../components/authProvider";

export function fetchAvailableData(msalInstance, vergehaverId) {
  return new Promise((resolve) => {
    const avvikUrl =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/availableData";

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(avvikUrl, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 400) {
          throw new Error(
            "Ugyldig fødselsnummer, vennligst kontroller data i søkefeltet. (feilkode : " +
              res.status +
              ")"
          );
        } else {
          throw new Error(
            "Kunne ikke finne skattedata for person, vennligst kontroller vergeansvaret i Vera. (feilkode : " +
              res.status +
              ")"
          );
        }
      })
      .then((data) => {
        if (data.length === 0) {
          const message = "Ingen data funnet for " + vergehaverId;
          resolve({ error: message });
        } else {
          resolve({ availableData: data });
        }
      })
      .catch((error) => {
        console.error(error.message);
        resolve({ error: error.message });
      });
  });
}

export function fetchSkattedata(msalInstance, vergehaverId, year, oneYear) {
  return new Promise((resolve) => {
    const url =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/skattegransking/" +
      year +
      "/" +
      oneYear;

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) return res.json();
        else
          throw new Error(
            "Kunne ikke hente skattedata  (feilkode : " + res.status + ")"
          );
      })
      .then((data) => {
        if (data.length === 0) {
          const message = "Ingen skattedata funnet for " + vergehaverId;
          resolve({ error: message });
        } else {
          resolve({ skattedata: data });
        }
      })
      .catch((error) => {
        console.error(error.message);
        resolve({ error: error.message });
      });
  });
}

export function sendToArchive(msalInstance, vergehaverId, year, saksid) {
  return new Promise((resolve) => {
    const url =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/arkiver/" +
      year +
      "/" +
      saksid;

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) resolve({});
        else
          throw new Error(
            "Kunne ikke sende til arkiv (feilkode : " + res.status + ")"
          );
      })
      .catch((error) => {
        console.error(error.message);
        resolve({ error: error.message });
      });
  });
}
