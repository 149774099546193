import { createAsyncThunk, createSlice, unwrapResult  } from "@reduxjs/toolkit";
import { fetchAvailableBankdata, fetchTransaksjoner, fetchRoller } from "./bankdataAPI";

const initialState = {
  loading: false,
  loadingTransactions: false,
  loadingRoller: false,
  error: "",
  tabIndex: 0
};

export const loadAvailableBankdata = createAsyncThunk(
  "bankdata/loadAvailableBankdata",
  async ({ msalInstance, vergehaverId }) => {
    const response = await fetchAvailableBankdata(msalInstance, vergehaverId);
    return response;
  }
);

export const loadTransaksjoner = createAsyncThunk(
  "bankdata/loadTransaksjoner",
  async ({ msalInstance, vergehaverId, accountNumber, year }) => {
    const response = await fetchTransaksjoner(msalInstance, vergehaverId, accountNumber, year);
    return response;
  }
);

export const loadRoller = createAsyncThunk(
  "bankdata/loadRoller",
  async ({ msalInstance, vergehaverId, accountNumber, year }) => {
    const response = await fetchRoller(msalInstance, vergehaverId, accountNumber, year);
    return response;
  }
);

export const loadBankdata = (values) => async (dispatch) => {
  const availableDataResult = await dispatch(loadAvailableBankdata(values));
  const availableData = unwrapResult(availableDataResult).availableBankdata.konti;

  if (!availableData) {
    return;
  }

  const accountNumber = availableData[0].accountNumber;
  const year = availableData[0].year;
  
  var res = await dispatch(
    loadTransaksjoner({ ...values, accountNumber, year })
  );

  if (res.payload.error) {
    return false;
  }  

  return dispatch(
    loadRoller({ ...values, accountNumber, year })
  );
}

export const bankdataSlice = createSlice({
  name: "bankdata",
  initialState,

  reducers: {
    resetError: (state, action) => {
      state.error = null;
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadAvailableBankdata.pending, (state) => {
        state.availableBankdata = null;
        state.transaksjoner = null;
        state.roller = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(loadAvailableBankdata.fulfilled, (state, action) => {
        state.loading = false;
        state.availableBankdata = action.payload.availableBankdata;
        state.error = action.payload.error;
      })
      .addCase(loadTransaksjoner.pending, (state) => {
        state.loadingTransactions = true;
        state.transaksjoner = null;
      })
      .addCase(loadTransaksjoner.fulfilled, (state, action) => {
        state.loadingTransactions = false;
        state.transaksjoner = action.payload.transaksjoner;
      })
      .addCase(loadRoller.pending, (state) => {
        state.loadingRoller = true;
        state.roller = null;
      })
      .addCase(loadRoller.fulfilled, (state, action) => {
        state.loadingRoller = false;
        state.roller = action.payload.roller;
      });
  },
});

export const { resetError, setTabIndex } = bankdataSlice.actions;

export const selectTabIndex = (state) => state.bankdata.tabIndex;

export const selectAvailableBankdata = (state) => state.bankdata.availableBankdata;

export const selectError = (state) => state.bankdata.error;

export const selectLoading = (state) => state.bankdata.loading;
export const selectLoadingTransactions = (state) => state.bankdata.loadingTransactions;
export const selectLoadingRoller = (state) => state.bankdata.loadingRoller;

export const selectTransaksjoner = (state) => state.bankdata.transaksjoner;
export const selectRoller = (state) => state.bankdata.roller;

export default bankdataSlice.reducer;
