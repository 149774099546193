import React, { useState, useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";

import { useDispatch } from "react-redux";

import { useMsal } from "@azure/msal-react";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    },
    inl: {
      display: "inline-block"
    }
  });

const SearchButton = ({ handleSearchClicked }) => {
    const classes = useStyles();
  
    return (
      <Button
        className={classes.root}
        onClick={handleSearchClicked}
        startIcon={<SearchIcon />}
      >
        Søk
      </Button>
    );
  };
  
  const Search = ({vergehaverId, onKeyPressEnter, onSearchClicked}) => {
    const dispatch = useDispatch();
    const msalInstance = useMsal().instance;
  
    const [textFieldContents, settextFieldContents] = useState(vergehaverId);

    return (
      <FormControl>
        <div className="search-header">
          <TextField
            className="w-150 left"
            id="inputFodselsnr"
            value={textFieldContents}
            onChange={(event) => settextFieldContents(event.target.value)}
            onKeyPress={(event) =>
              onKeyPressEnter(event, dispatch, msalInstance, textFieldContents)
            }
            label="Fødselsnr."
            variant="standard"
          />
          <div className="left search-button">
            <SearchButton
              style={{ marginTop: "12px !important;" }}
              className="search-button w-150 left"
              handleSearchClicked={() =>
                onSearchClicked(dispatch, msalInstance, textFieldContents)
              }
            />
          </div>
        </div>
      </FormControl>
    );  
  }

  export default Search;