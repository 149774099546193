import React from 'react'

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const YearSelector = ({
    selectedYear,
    onChangeYear,
    availableData,
    showOneYear,
  }) => {
    const years = availableData
      .map((data) => data.year)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort()
      .reverse();
  
    const hasSuccessor = (year) => years.includes(year + 1);
    const hasPredecessor = (year) => years.includes(year - 1);
  
    return (
      <FormControl>
        <InputLabel id="year-select-label">Regnskapsår</InputLabel>
        <Select
          labelId="year-select-label"
          id="year-select"
          value={
            selectedYear
              ? selectedYear
              : Math.max(...availableData.map((data) => data.year))
          }
          label="Regnskapsår"
          onChange={(e) => onChangeYear(showOneYear, e)}
          sx={{ minWidth: "100px" }}
        >
          {showOneYear
            ? years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))
            : years
                .filter((year) => hasPredecessor(year) || !hasSuccessor(year))
                .map((year, index) => (
                  <MenuItem key={index} value={year}>
                    {year}
                    {hasPredecessor(year) ? "/" + (year - 1) : ""}
                  </MenuItem>
                ))}
        </Select>
      </FormControl>
    );
  };

export default YearSelector;  