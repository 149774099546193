import withStyles from "@mui/styles/withStyles";
import { TableCell, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import RolleRad from "./rollerad";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#FBFBFB'
    }
  }))(TableCell);

const Roller = ({roller}) => {
    return (
        <TableContainer>
            <Table sx={{ maxWidth: "1380px" }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: "20px" }}>&nbsp;</StyledTableCell>
                        <StyledTableCell sx={{ width: "200px" }}>Rettighet til bruk</StyledTableCell>
                        <StyledTableCell sx={{ width: "140px" }}>Rollehaver</StyledTableCell>
                        <StyledTableCell sx={{ width: "140px" }}>Startdato</StyledTableCell>
                        <StyledTableCell sx={{ width: "140px" }}>Sluttdato</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                roller && roller.map((rolle, i) => (
                    <RolleRad key={i} index={i} rolle={rolle} />
                ))
                }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default Roller;