import React, { useEffect } from 'react';
import './css/App.css';
import './css/bootstrap.min.css';
import Spinner from './components/spinner';
import ErrorMessage from './components/errorMessage';
import App from './App';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { checkAppAccess, getNnin, selectLoading, selectLoadingVergehaverNnin, selectAuthorized } from './features/app/appSlice';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AppAuthorization = ( { instance, view } ) => {
  const loading = useSelector(selectLoading);
  const loadingNnin = useSelector(selectLoadingVergehaverNnin);
  const authorized = useSelector(selectAuthorized);

  const dispatch = useDispatch();

  const query = useQuery();

  const vergehaverVeraId  = query.get("id");

  useEffect(() => {
    dispatch(checkAppAccess(instance));

    if (vergehaverVeraId && vergehaverVeraId !== '') {
      dispatch(getNnin({ msalInstance: instance, vergehaverVeraId: vergehaverVeraId }));
    }
  }, [instance, dispatch, vergehaverVeraId]);

  return (
    <div>
      { (loading || loadingNnin) && <Spinner loading={ loading } /> }

      { authorized && !loadingNnin && <App msalInstance={ instance } view={view}/> }
      
      { !loading && !authorized && <ErrorMessage message="Ingen tilgang" /> }        
    </div>
  );
}

export default AppAuthorization;