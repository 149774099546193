import React, { Fragment } from 'react'
import { TableRow, TableCell } from '@mui/material';
import Moment from 'moment';
import IconButton from "@mui/material/IconButton";
import SvgMore from "@mui/icons-material/ExpandMore";
import SvgLess from "@mui/icons-material/ExpandLess";
import makeStyles from "@mui/styles/makeStyles";
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    },
    inl: {
      display: "inline-block"
    }
  });
 
const ToggleExpandButton = ({ handleClicked, expanded}) => {
    const classes = useStyles();
    return (
        <IconButton onClick={handleClicked} size="small" className={classes.root}>
            {expanded && <SvgLess />}
            {!expanded && <SvgMore />}
        </IconButton>
    );
}  

const TransaksjonRad = ({index, trans}) => {
    
    const [expand, setExpand] = React.useState(false);
    
    const handleExpand = () => {
        setExpand(!expand);
    };

    return [
        <Fragment key={trans.id}> 
            <TableRow>
                <TableCell sx={{ width: "20px" }}>
                    <ToggleExpandButton handleClicked={handleExpand} expanded={expand} />
                </TableCell>
                <TableCell sx={{ width: "140px" }}>{Moment(trans.bokfoertdato).format('DD.MM.YYYY')}</TableCell>
                <TableCell sx={{ width: "140px" }}>{trans.type}</TableCell>
                <TableCell sx={{ width: "490px" }}>
                    {trans.beskrivelse}
                </TableCell>
                <TableCell align="right" sx={{ width: "140px" }}>
                    <NumericFormat value={trans.inn} displayType="text" thousandSeparator=" "  decimalSeparator="," />
                </TableCell>
                <TableCell align="right" sx={{ width: "140px" }}>
                    <NumericFormat value={trans.ut} displayType="text" thousandSeparator=" "  decimalSeparator=","/>
                </TableCell>
                <TableCell sx={{ width: "140px" }}>
                    {trans.motpart && trans.motpart.kontonummer}
                </TableCell>
                <TableCell sx={{ width: "170px" }}>
                    {trans.motpart && trans.motpart.navn}
                </TableCell>
            </TableRow>
            { expand && 
            <TableRow>
                <TableCell colSpan={8}>
                    <table cellPadding={2}>
                        <tbody>
                            <tr><td><strong>Detaljer</strong></td><td></td><td><strong>Motpart</strong></td><td></td></tr>
                            <tr><td>Transaksjonsid:</td><td>{trans.id}</td><td>Kontonummer:</td><td>{(trans.motpart && trans.motpart.kontonummer) ? trans.motpart.kontonummer : ""}</td></tr>
                            <tr><td>Rentedato:</td><td>{Moment(trans.rentedato).format('DD.MM.YYYY')}</td><td>Navn:</td><td>{(trans.motpart && trans.motpart.navn) ? trans.motpart.navn : ""}</td></tr>
                            <tr><td>Bokførtdato:</td><td>{Moment(trans.bokfoertdato).format('DD.MM.YYYY')}</td><td>Addresse:</td><td>{(trans.motpart && trans.motpart.postadresse) ? trans.motpart.postadresse : ""}</td></tr>
                            <tr><td valign='top'>Beskrivelse:</td><td width={200}>{trans.beskrivelse}</td></tr>
                        </tbody>
                    </table>
                </TableCell>
            </TableRow> 
            }
        </Fragment>
    ];

}

export default TransaksjonRad;