import { createAsyncThunk, createSlice, unwrapResult } from "@reduxjs/toolkit";
import { fetchSkattedata, fetchAvailableData, sendToArchive } from "./skattedataAPI";

const initialState = {
  loading: false,
  error: "",
  tabIndex: 0
};

export const loadAvailableData = createAsyncThunk(
  "skattedata/loadAvailableData",
  async ({ msalInstance, vergehaverId }) => {
    const response = await fetchAvailableData(msalInstance, vergehaverId);
    return response;
  }
);

export const loadSkattedata = createAsyncThunk(
  "skattedata/loadSkattedata",
  async ({ msalInstance, vergehaverId, year, oneYear }) => {
    const response = await fetchSkattedata(
      msalInstance,
      vergehaverId,
      year,
      oneYear
    );
    return response;
  }
);

export const archive = createAsyncThunk(
  "skattedata/archive",
  async ({ msalInstance, vergehaverId, year, saksid }) => {
    const response = await sendToArchive(
      msalInstance,
      vergehaverId,
      year,
      saksid
    );
    return response;
  }
);

export const loadAll = (values) => async (dispatch) => {
  const availableDataResult = await dispatch(loadAvailableData(values));
  const availableData = unwrapResult(availableDataResult).availableData;

  if (!availableData) {
    return;
  }

  const year = Math.max(...availableData.map((data) => data.year));
  var skattendataOneYearRes = await dispatch(
    loadSkattedata({ ...values, year, oneYear: true })
  );
  if (skattendataOneYearRes.payload.error) {
    return false;
  }

  return dispatch(loadSkattedata({ ...values, year, oneYear: false }));
};

export const skattedataSlice = createSlice({
  name: "skattedata",
  initialState,

  reducers: {
    setSelectedYear: (state, action) => {
      state.selectedYear = action.payload;
    },
    setSelectedOneYear: (state, action) => {
      state.selectedOneYear = action.payload;
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    resetError: (state, action) => {
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadAvailableData.pending, (state) => {
        state.availableData = null;
        state.loading = true;
        state.error = null;
      })
      .addCase(loadAvailableData.fulfilled, (state, action) => {
        state.loading = false;
        state.availableData = action.payload.availableData;
        state.error = action.payload.error;
        state.skattedata = null;
        state.skattedataOneYear = null;
      })
      .addCase(loadSkattedata.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadSkattedata.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg.oneYear) {
          state.skattedataOneYear = action.payload.skattedata;
        } else {
          state.skattedata = action.payload.skattedata;
        }
        state.error = action.payload.error;
      })
      .addCase(archive.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(archive.fulfilled, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});


export const { setSelectedYear, setSelectedOneYear, setTabIndex, resetError } = skattedataSlice.actions;

export const selectTabIndex = (state) => state.skattedata.tabIndex;

export const selectSelectedYear = (state) => state.skattedata.selectedYear;

export const selectSelectedOneYear = (state) => state.skattedata.selectedOneYear;

export const selectError = (state) => state.skattedata.error;

export const selectLoading = (state) => state.skattedata.loading;

export const selectSkattedata = (state) => state.skattedata.skattedata;

export const selectSkattedataOneYear = (state) =>
  state.skattedata.skattedataOneYear;

export const selectAvailableData = (state) => state.skattedata.availableData;

export default skattedataSlice.reducer;
