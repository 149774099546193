import React from 'react'
import { TableRow, TableCell } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const GrunnlagRad = ({grunnlag}) => {
    return (
        <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>{grunnlag.navn}</TableCell>
            <TableCell>&nbsp;</TableCell>
            <TableCell align="right">{ grunnlag.kategori === "inntekt" ? <NumericFormat value={grunnlag.beloep} displayType="text" thousandSeparator={' '}/> : 0 }</TableCell>
            <TableCell align="right">{ grunnlag.kategori === "formue" ? <NumericFormat value={grunnlag.beloep} displayType="text" thousandSeparator={' '}/> : 0 }</TableCell>
            <TableCell align="right">{ grunnlag.kategori === "inntektsfradrag" ? <NumericFormat value={grunnlag.beloep} displayType="text" thousandSeparator={' '}/> : 0 }</TableCell>
            <TableCell align="right">{ grunnlag.kategori === "formuesfradrag" ? <NumericFormat value={grunnlag.beloep} displayType="text" thousandSeparator={' '}/> : 0 }</TableCell>
        </TableRow>        
    );
}

export default GrunnlagRad;