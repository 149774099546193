import { Environment } from "../../env";
import { scopes } from '../../components/authProvider';

export function hasAppAccess(msalInstance) {
  return new Promise((resolve) => {
    
    const url = Environment.baseUrl + '/api/access';

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0]
    };

    msalInstance.acquireTokenSilent(request)
    .then(token => 
      fetch(url, { 
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token.accessToken
          }
        })
    )
    .then(res => {
        if (res.ok) {
          res.json().then(
            (value) => {
              resolve( { authorized: true, accessToBankdata: value.accessToBankdata } );
            }
          );
        } else {
          resolve({ authorized: false, accessToBankdata: false });  
        }
      }
    )
    .catch((error) => {
      console.error(error);
      resolve({ authorized: false })
    })});
}

export function fetchVerghaverNnin(msalInstance, vergehaverVeraId)  {
  return new Promise((resolve) => {
    
    const url = Environment.baseUrl + '/api/nnin/' + vergehaverVeraId.replace(/\s/g, '');

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0]
    };

    msalInstance.acquireTokenSilent(request)
    .then(token => 
      fetch(url, { 
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token.accessToken
          }
        })
    )
    .then(res => {
        resolve({ nnin: res.text() })
      }
    )
    .catch((error) => {
      console.error(error);
      resolve({ nnin: '' })
    })});
}