import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { TableCell, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import SortButton from "../common/sortButton";
import TransaksjonRad from "./transaksjonrad";
import InfiniteScroll from 'react-infinite-scroll-component';
import Moment from 'moment';

// https://mui.com/material-ui/react-table/
const pageSize = 50;

function descendingComparator(a, b, orderBy) {
    
    if (orderBy === "Motkonto") {
        const ak = a.motpart != null ? (a.motpart.kontonummer != null ? a.motpart.kontonummer : "") : "";
        const bk = b.motpart != null ? (b.motpart.kontonummer != null ? b.motpart.kontonummer : "") : "";

        if (bk < ak) {
            return -1;
        }
        if (bk > ak) {
            return 1;
        }
        return 0;     
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function getPage(pageNum, transaksjoner) {
    if (!transaksjoner) {
        return [];
    }
    return transaksjoner.slice(pageSize * pageNum, Math.min(pageSize * (pageNum + 1), transaksjoner.length));
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#FBFBFB'
    }
  }))(TableCell);

const Transaksjoner = ({ transaksjoner, month, motkonto }) => {
    const [pageNum, setPageNum] = React.useState(0);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('Bokfoertdato');
    const [transaksjonerPage, setTransaksjonerPage] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    
    const filterTransaksjoner = (transaksjoner) => {
        if (!transaksjoner) {
          return [];
        }
      
        var ts = month === '0' ? transaksjoner : transaksjoner.filter(trans => Moment(trans.bokfoertdato).format('M') === month);
        return (motkonto === '0' ? ts : ts.filter(trans => trans.motpart?.kontonummer === motkonto));
    }

    const sortAndFilter = (transaksjoner, order, orderBy) => {
        if (!transaksjoner) {
            return [];
        } 

        return filterTransaksjoner(transaksjoner).slice().sort(getComparator(order, orderBy));
    }
    
    const [sAndFTransaksjoner, setSAndFTransaksjoner] = React.useState(transaksjoner !== null ? transaksjoner : []);
    

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
       
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        var sf = sortAndFilter(transaksjoner, isAsc ? 'desc' : 'asc', property)
        setSAndFTransaksjoner(sf);

        setPageNum(0);
        setHasMore(true);
        setTransaksjonerPage(getPage(0, sf));
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const fetchMoreData = () => {
        const nextPage = pageNum + 1;
        setPageNum(nextPage);

        var tp = transaksjonerPage.concat(getPage(nextPage, sAndFTransaksjoner));
        setTransaksjonerPage(tp);
        setHasMore(tp.length < sAndFTransaksjoner.length)
        return;
    }
    
    useEffect(() => {
        var sf = sortAndFilter(transaksjoner, order, orderBy);
        setSAndFTransaksjoner(sf);

        setPageNum(0);
        setHasMore(true);
        setTransaksjonerPage(getPage(0, sf));
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [month, motkonto])

    return (
        <InfiniteScroll
                    dataLength={transaksjonerPage.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<div/>}
                    endMessage={<div/>}
                >          
                    
        <TableContainer>
            <Table sx={{ maxWidth: "1380px" }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: "20px" }}>&nbsp;</StyledTableCell>
                        <StyledTableCell align="left" sx={{ width: "140px" }}>
                            <SortButton
                                active={orderBy === "Bokfoertdato"}
                                direction={orderBy === "Bokfoertdato" ? order : 'asc'}
                                onClick={createSortHandler("Bokfoertdato")}
                            >
                            Dato&nbsp;
                            </SortButton>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "140px" }}>
                            <SortButton
                                active={orderBy === "Type"}
                                direction={orderBy === "Type" ? order : 'asc'}
                                onClick={createSortHandler("Type")}
                            >
                            Type&nbsp;
                            </SortButton>
                            </StyledTableCell>
                        <StyledTableCell sx={{ width: "490px" }}>
                            <SortButton
                                active={orderBy === "Beskrivelse"}
                                direction={orderBy === "Beskrivelse" ? order : 'asc'}
                                onClick={createSortHandler("Beskrivelse")}
                            >
                            Beskrivelse
                            </SortButton>
                        </StyledTableCell>
                        <StyledTableCell align="right" sx={{ width: "140px" }}>
                            <SortButton
                                active={orderBy === "Inn"}
                                direction={orderBy === "Inn" ? order : 'asc'}
                                onClick={createSortHandler("Inn")}
                                numeric={true}
                            >
                            Inn 
                            </SortButton>
                        </StyledTableCell>
                        <StyledTableCell align="right" sx={{ width: "140px" }}>
                            <SortButton
                                active={orderBy === "Ut"}
                                direction={orderBy === "Ut" ? order : 'asc'}
                                onClick={createSortHandler("Ut")}
                                numeric={true}
                            >
                            Ut
                            </SortButton>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "140px" }}>
                            <SortButton
                                active={orderBy === "Motkonto"}
                                direction={orderBy === "Motkonto" ? order : 'asc'}
                                onClick={createSortHandler("Motkonto")}
                            >
                            Motkonto
                            </SortButton>
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "170px" }}>
                            <SortButton
                                active={orderBy === "Motpart"}
                                direction={orderBy === "Motpart" ? order : 'asc'}
                                onClick={createSortHandler("Motpart")}
                            >
                            Motpart
                            </SortButton>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                transaksjonerPage.map((trans) => (
                    <TransaksjonRad key={trans.id} trans={trans} />
                ))
                }
                </TableBody>
            </Table>
        </TableContainer> 
        </InfiniteScroll>
    );
}

export default Transaksjoner;