import React from 'react';
import {createRoot} from 'react-dom/client';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import AppAuthorization from './AppAuthorization';
import { authRequest, configuration } from './components/authProvider';
import { store } from './app/store';
import { Provider } from 'react-redux';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function ErrorComponent({error}) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

const msalInstance = new PublicClientApplication(configuration);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={LoadingComponent}>
         <Router>
          <Routes>
            <Route path="/bankdata" element={<AppAuthorization store={store} instance={msalInstance} view="bankdata" />} />
            <Route path="/skattedata" element={<AppAuthorization store={store} instance={msalInstance} view="skattedata" />} />
            <Route path="/" element={<AppAuthorization store={store} instance={msalInstance} view="skattedata"/>} />
          </Routes>
         </Router>   
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </Provider>,
);
