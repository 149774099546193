import React, { useEffect } from "react";
import "./css/App.css";
import HeaderBar from "./components/header";
import "react-tabs/style/react-tabs.css";

import { Environment } from "./env";

import Button from "@mui/material/Button";
import Spinner from "./components/spinner";
import ErrorMessage from "./components/errorMessage";
import Skattedata from "./components/skattedata/skateedata"
import Bankdata from "./components/bankdata/bankdata"
import Search from "./components/search";
import CastleIcon from '@mui/icons-material/Castle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';

import {Helmet} from "react-helmet";

import { GlobalStyles } from "@mui/material";

import Link from '@mui/material/Link';
import { Link as RouterLink} from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

import {
  selectError,
  selectLoading,
  selectAvailableData,
  loadAll as loadAllSkattedata,
  resetError as resetSkattedataError,
} from "./features/skattedata/skattedataSlice";

import {
  selectError as selectBankdataError,
  selectLoading as selectBankdataLoading,
  selectAvailableBankdata,
  resetError as resetBankdataError,
  loadBankdata
} from "./features/bankdata/bankdataSlice";

import { setVergehaverId } from './features/app/appSlice';


import { selectVergehaverId, selectAuthorizedForBankdata } from "./features/app/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";

import makeStyles from "@mui/styles/makeStyles";

const theme = createTheme({ palette: { primary: { main: "#000000" } } });

const useStyles = makeStyles({
  root: {
    padding: 0,
    border: 0,
    justifyContent: "right",
    "&:focus": {
      outline: 0,
      border: 0,
    },
    textTransform: 'none',
    fontSize: '12pt' 
  }
});

const App = ({view}) => {
  const dispatch = useDispatch();
  const msalInstance = useMsal().instance;

  const skattedataError = useSelector(selectError);
  const bankdataError = useSelector(selectBankdataError);

  const error = skattedataError ? skattedataError : bankdataError
  
  const loadingSkattedata = useSelector(selectLoading);
  const loadingBankdata = useSelector(selectBankdataLoading);
  const loading = loadingSkattedata || loadingBankdata;

  const vergehaverId = useSelector(selectVergehaverId);
  const availableData = useSelector(selectAvailableData);
  const authorizedForBankdata = useSelector(selectAuthorizedForBankdata);

  const availableBankdata = useSelector(selectAvailableBankdata);

  const dispatchLoadAllSkattedata = (dispatch, msalInstance, vergehaverId) => {
    dispatch(
      loadAllSkattedata({
        msalInstance: msalInstance,
        vergehaverId: vergehaverId,
      })
    );
  }
  
  const dispatchLoadAvailableBankdata = (dispatch, msalInstance, vergehaverId) => {
    dispatch(
      loadBankdata({
        msalInstance: msalInstance,
        vergehaverId: vergehaverId,
      })
    );
  }

  const onKeyPressEnter = (event, dispatch, msalInstance, vergehaverId) => {
    if (event.charCode === 13) {
      onSearchClicked (dispatch, msalInstance, vergehaverId);
    }
  };

  const onSearchClicked = (dispatch, msalInstance, vergehaverId) => {
    dispatch(setVergehaverId(vergehaverId));

    if (vergehaverId === "") {
      return;   
    }

    if (view === "skattedata") {
      dispatchLoadAllSkattedata(dispatch, msalInstance, vergehaverId);
    } else if (view === "bankdata") {
      dispatchLoadAvailableBankdata(dispatch, msalInstance, vergehaverId);
    }
  };

  const skateedataLinkClicked  = (dispatch, msalInstance, vergehaverId) => {
    dispatch(resetBankdataError());

    if (vergehaverId === "") {
      return;   
    }

    dispatchLoadAllSkattedata(dispatch, msalInstance, vergehaverId);
  }

  const bankdataLinkClicked = (dispatch, msalInstance, vergehaverId) => {
    dispatch(resetSkattedataError());
    
    if (vergehaverId === "") {
      return;   
    }
    
    dispatchLoadAvailableBankdata(dispatch, msalInstance, vergehaverId);
  }

  useEffect(() => {
    if (vergehaverId !== "") {
      if (view === "skattedata") {
        dispatchLoadAllSkattedata(dispatch, msalInstance, vergehaverId);
      } 
    }
  }, [msalInstance, dispatch, view, vergehaverId]);

  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <Helmet>
        <meta 
          http-equiv="Content-Security-Policy" 
          content={"default-src 'self'; script-src 'self'; style-src 'self' 'unsafe-inline'; connect-src 'self' " + Environment.baseUrl + " https://login.microsoftonline.com;"} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            body: { backgroundColor: "#EBEBEB" }
          }}
        />
        <div>
          <nav>
            <HeaderBar />
          </nav>
          <div className="appLayout">
          <div> 
            <Search vergehaverId={vergehaverId} onKeyPressEnter={onKeyPressEnter} onSearchClicked={onSearchClicked} />
            
            <div className="navMenu">
              <Button
                aria-label='Skattedata'
                color='inherit'
                className='navButton'
                startIcon={<CastleIcon />}>
                { view === "skattedata" &&  <Link sx={{ textDecoration: "none", fontWeight: "bold"} } component={RouterLink} to="/skattedata">Skattedata</Link> }
                { view !== "skattedata" &&  <Link sx={{ textDecoration: "none"} } component={RouterLink} onClick={() => skateedataLinkClicked(dispatch, msalInstance, vergehaverId)} to="/skattedata">Skattedata</Link> }
              </Button>  
          

            { authorizedForBankdata && 
              <Button
                aria-label='Bankdata'
                color='inherit'
                className='navButton'
                startIcon={<AccountBalanceIcon />}>
                { view === "bankdata" && <Link sx={{ textDecoration: "none", fontWeight: "bold"} } component={RouterLink} to="/bankdata">Bankdata</Link> }
                { view !== "bankdata" && <Link sx={{ textDecoration: "none"} } component={RouterLink} onClick={() => bankdataLinkClicked(dispatch, msalInstance, vergehaverId)} to="/bankdata">Bankdata</Link> }   
              </Button>
            }

              <Divider className='logoutNavButtonDivider'/>

              <Button
                aria-label='Logg ut'
                color='inherit'
                className='navButton logoutNavButton'
                onClick={() => msalInstance.logout()}
                startIcon={<LogoutIcon />}>
                Logg ut  
              </Button>

            </div>  
          </div>
          <div>
            { loading && <Spinner loading={loading} /> }
            { error && error !== "" && <ErrorMessage message={error} /> }

            { !loading && availableBankdata && view === "bankdata" && (<Bankdata vergehaverId={vergehaverId} />) }
            { !loading && availableData && view === "skattedata" && (<Skattedata vergehaverId={vergehaverId} />) }
            
          </div>
          </div>
        </div>     
       </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
