import { Environment } from "../../env";
import { scopes } from "../../components/authProvider";

export function fetchAvailableBankdata(msalInstance, vergehaverId) {

  return new Promise((resolve) => {
    const url =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/bankdata/availableData";

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) return res.json();
        else if (res.status === 400) {
          throw new Error(
            "Ugyldig fødselsnummer, vennligst kontroller data i søkefeltet. (feilkode : " +
              res.status +
              ")"
          );
        } else {
          throw new Error(
            "Kunne ikke finne bankdata for person, vennligst kontroller vergeansvaret i Vera. (feilkode : " +
              res.status +
              ")"
          );
        }
      })
      .then((data) => {
        if (data.length === 0) {
          const message = "Ingen data funnet for " + vergehaverId;
          resolve({ error: message });
        } else {
          resolve({ availableBankdata: data });
        }
      })
      .catch((error) => {
        resolve({ error: error.message });
      });
  });
}

export function fetchTransaksjoner(msalInstance, vergehaverId, accountNumber, year) {
  return new Promise((resolve) => {
    const url =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/account/" +
      accountNumber +
      "/transactions/" +
      year;

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) return res.json();
        else
          throw new Error(
            "Kunne ikke hente bankdata  (feilkode : " + res.status + ")"
          );
      })
      .then((data) => {
        if (data.length === 0) {
          const message = "Ingen bankdata funnet for kontonummer: " + accountNumber;
          resolve({ error: message });
        } else {
          resolve({ transaksjoner: data });
        }
      })
      .catch((error) => {
        console.error(error.message);
        resolve({ error: error.message });
      });
  });
}

export function fetchRoller(msalInstance, vergehaverId, accountNumber, year) {
  return new Promise((resolve) => {
    const url =
      Environment.baseUrl +
      "/api/nnin/" +
      vergehaverId.replace(/\s/g, "") +
      "/account/" +
      accountNumber +
      "/roles/" +
      year;

    const request = {
      scopes: scopes,
      account: msalInstance.getAllAccounts()[0],
    };

    msalInstance
      .acquireTokenSilent(request)
      .then((token) =>
        fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.accessToken,
          },
        })
      )
      .then((res) => {
        if (res.ok) return res.json();
        else
          throw new Error(
            "Kunne ikke hente bankdata (feilkode : " + res.status + ")"
          );
      })
      .then((data) => {
        if (data.length === 0) {
          const message = "Ingen roller funnet for kontonummer: " + accountNumber;
          resolve({ error: message });
        } else {
          resolve({ roller: data });
        }
      })
      .catch((error) => {
        console.error(error.message);
        resolve({ error: error.message });
      });
  });
}