import React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import makeStyles from "@mui/styles/makeStyles";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {
    archive
  } from "../../features/skattedata/skattedataSlice";

const useStyles = makeStyles({
    root: {
      border: 0,
      "&:focus": {
        outline: 0,
        border: 0,
      },
    }
  });

const OkButton = ({handleSearchClicked}) => {
    const classes = useStyles();
    return (
      <Button
        className={classes.root}
        onClick={handleSearchClicked}>
        Ok
      </Button>
    );
}

const CloseButton = ({handleSearchClicked}) => {
    const classes = useStyles();
    return (
      <Button
        className={classes.root}
        onClick={handleSearchClicked}>
        Avbryt
      </Button>
    );
}    

const ArkiverButton = ({saker, vergehaverId, year}) => {
    const [open, setOpen] = React.useState(false);
    const [sak, setSak] = React.useState(saker[0]);
    const classes = useStyles();
    const msalInstance = useMsal().instance;
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        dispatch(
            archive({
              msalInstance: msalInstance,
              vergehaverId: vergehaverId,
              year: year, 
              saksid: saker[0],
            })
          );
         setOpen(false);  
    };

    const handleSakChange = (event) => {
      setSak(event.target.value);
    };

    return (
        <div>
            <Button 
                  onClick={handleClickOpen}
                  style={{ marginTop: "20px" }}
                  className={classes.root}
                  startIcon={<SendAndArchiveIcon />}
            >
                Arkiver
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}>
                <DialogTitle>Lagre i dokumentarkivet</DialogTitle>
                <DialogContent>
                   <span>Arkiver på sak:</span>
                   <Select
                    value={sak}
                    label="Sak"
                    onChange={handleSakChange}
                    className={classes.root}
                    style={{ marginLeft: "30px" }}
                    >
                  {saker.map((s, i) => <MenuItem key={i} className={classes.root} value={s}>{s}</MenuItem>)}
                </Select>

                </DialogContent>
                <DialogActions>
                    <OkButton handleSearchClicked={handleOk} />
                    <CloseButton handleSearchClicked={handleClose} />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ArkiverButton;