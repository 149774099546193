import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../features/app/appSlice';
import skattedataReducer from '../features/skattedata/skattedataSlice';
import bankdataReducer from '../features/bankdata/bankdataSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    skattedata: skattedataReducer,
    bankdata: bankdataReducer,
    middleware: []
  }
});
