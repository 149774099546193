import React from 'react'
import { TableRow, TableCell } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import withStyles from '@mui/styles/withStyles';
import SpesifiseringPopup from './spesifiseringPopup';
import Box from '@mui/material/Box';

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
    }
  }))(TableRow);
  
const SpesifiseringRad = ({spesifisering}) => {
    const isTotal = spesifisering.spesifiseringType  === "Totalt";

    if (isTotal)
    {
        return (
            <StyledTableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell><Box fontWeight="fontWeightBold">{spesifisering.spesifiseringType}</Box></TableCell>
                <TableCell><Box fontWeight="fontWeightBold">{spesifisering.beskrivelse}</Box></TableCell>
                <TableCell align="right"><Box fontWeight="fontWeightBold"><NumericFormat value={spesifisering.inntekt} displayType="text" thousandSeparator={' '}/></Box></TableCell>
                <TableCell align="right"><Box fontWeight="fontWeightBold"><NumericFormat value={spesifisering.formue} displayType="text" thousandSeparator={' '}/></Box></TableCell>
                <TableCell align="right"><Box fontWeight="fontWeightBold"><NumericFormat value={spesifisering.inntektsfradrag} displayType="text" thousandSeparator={' '}/></Box></TableCell>
                <TableCell align="right"><Box fontWeight="fontWeightBold"><NumericFormat value={spesifisering.formuesfradrag} displayType="text" thousandSeparator={' '}/></Box></TableCell>
            </StyledTableRow>        
        );
    }
    
    return (
        <TableRow>
            <TableCell><SpesifiseringPopup props={spesifisering}/></TableCell>
            <TableCell>{spesifisering.spesifiseringType}</TableCell>
            <TableCell>{spesifisering.beskrivelse}</TableCell>
            <TableCell align="right"><NumericFormat value={spesifisering.inntekt} displayType="text" thousandSeparator={' '}/></TableCell>
            <TableCell align="right"><NumericFormat value={spesifisering.formue} displayType="text" thousandSeparator={' '}/></TableCell>
            <TableCell align="right"><NumericFormat value={spesifisering.inntektsfradrag} displayType="text" thousandSeparator={' '}/></TableCell>
            <TableCell align="right"><NumericFormat value={spesifisering.formuesfradrag} displayType="text" thousandSeparator={' '}/></TableCell>
        </TableRow>        
    );
}

export default SpesifiseringRad;