import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
} from "@mui/material";
import Temadetaljerrad from "./temadetaljerrad";
import withStyles from "@mui/styles/withStyles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#1E88E5",
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
    fontWeight: "bold",
  },
}))(TableCell);

const Temadetaljer = ({
  temadetaljerAarA,
  temadetaljerAarB,
  aarA,
  aarB,
  spesifiseringerAarA,
  spesifiseringerAarB,
}) => {
  const detaljerAarA = temadetaljerAarA
    ? temadetaljerAarA
    : {
        aar: aarA,
        inntekt: 0,
        formue: 0,
        inntektsfradrag: 0,
        formuesfradrag: 0,
        stadie: "Ingen i ",
      };
  const detaljerAarB = temadetaljerAarB
    ? temadetaljerAarB
    : {
        aar: aarB,
        inntekt: 0,
        formue: 0,
        inntektsfradrag: 0,
        formuesfradrag: 0,
        stadie: "Ingen i ",
      };

  const showRows = aarB > 0;

  return (
    <TableContainer>
      <Table sx={{ maxWidth: "1380px" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: "20px" }}>&nbsp;</StyledTableCell>
            <StyledTableCell sx={{ width: "400px" }}>&nbsp;</StyledTableCell>
            <StyledTableCell sx={{ width: "400px" }}>&nbsp;</StyledTableCell>
            <StyledTableCell align="right" sx={{ width: "140px" }}>
              Inntekt
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ width: "140px" }}>
              Formue
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ width: "140px" }}>
              Inntektsfradrag
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ width: "140px" }}>
              Formuesfradrag
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {showRows && (
          <Temadetaljerrad
            rowType={detaljerAarB.stadie}
            beskrivelse={detaljerAarB.stadie + " " + detaljerAarB.aar}
            inntekt={detaljerAarB.inntekt}
            formue={detaljerAarB.formue}
            inntektsfradrag={detaljerAarB.inntektsfradrag}
            formuesfradrag={detaljerAarB.formuesfradrag}
            grunnlag={detaljerAarB.tekniskeNavn}
            spesifiseringer={spesifiseringerAarB}
            summary={false}
          />
        )}
        <Temadetaljerrad
          rowType={detaljerAarA.stadie}
          beskrivelse={detaljerAarA.stadie + " " + detaljerAarA.aar}
          inntekt={detaljerAarA.inntekt}
          formue={detaljerAarA.formue}
          inntektsfradrag={detaljerAarA.inntektsfradrag}
          formuesfradrag={detaljerAarA.formuesfradrag}
          grunnlag={detaljerAarA.tekniskeNavn}
          spesifiseringer={spesifiseringerAarA}
          summary={false}
        />
        {showRows && (
          <Temadetaljerrad
            rowType="Differanse"
            beskrivelse="Differanse"
            inntekt={detaljerAarB.inntekt - detaljerAarA.inntekt}
            formue={detaljerAarB.formue - detaljerAarA.formue}
            inntektsfradrag={
              detaljerAarB.inntektsfradrag - detaljerAarA.inntektsfradrag
            }
            formuesfradrag={
              detaljerAarB.formuesfradrag - detaljerAarA.formuesfradrag
            }
            grunnlag={null}
            spesifiseringer={null}
            summary={true}
          />
        )}
      </Table>
    </TableContainer>
  );
};

export default Temadetaljer;
